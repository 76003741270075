import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import { auth } from "../config/firebase";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiredAuth: true,
      title: 'Home'
    }
  },

  {
    path: '/properties',
    name: 'properties',
    component: () => import(/* webpackChunkName: "properties" */ '../views/PropertiesPage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Properties'
    }
  },
  {
    path: '/add-property',
    name: 'addProperties',
    component: () => import(/* webpackChunkName: "add-properties" */ '../components/PropertyManagement/addProperty.vue'),
    meta: {
      requiredAuth: true,
      title: 'Add Property'
    }
  },
  {
    path: "/properties/:id",
    name: "view-property",
    component: () =>
      import(
        /* webpackChunkName: "view-property" */ "../components/PropertyManagement/viewProperty.vue"
      ),
    meta: {
      requiredAuth: true,
      title: 'Property Info'
    },
  },
  {
    path: "/users/:id",
    name: "view-user",
    component: () =>
      import(
        /* webpackChunkName: "view-user" */ "../components/UserManagement/viewUser.vue"
      ),
    meta: {
      requiredAuth: true,
      title: 'User Info'
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordPage',
    component: () => import('@/views/ForgotPassword.vue'),
    meta:{
      requireAuth: false,
      title: 'Forgot Password'
    }
  },
  {
    path: "/properties/edit/:id",
    name: "editProperty",
    component: () =>
      import(
        /* webpackChunkName: "edit-property" */ "../components/PropertyManagement/editProperties.vue"
      ),
    meta: {
      requiredAuth: true,
      title: 'Edit Properties'
    },
  },

  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UserPage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Users'
    }
  },

  {
    path: '/all-submitted-property',
    name: 'Submitted Property',
    component: () => import(/* webpackChunkName: "Submitted" */ '../views/SubmittedProperty.vue'),
    meta: {
      requiredAuth: true,
      title: 'Submitted Property'
    }
  },

  {
    path: '/brand-requirement',
    name: 'brand-requirement',
    component: () => import(/* webpackChunkName: "brand-requirement" */ '../views/BrandRequirementPage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Brand Requirements'
    }
  },
  {
    path: '/smart-search',
    name: 'smart-search',
    component: () => import(/* webpackChunkName: "smart-search" */ '../views/SmartSearchPage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Smart Search'
    }
  },
  {
    path: "/brand-requirement/:id",
    name: "view-brand-requirement",
    component: () =>
      import(
        /* webpackChunkName: "view-brand-requirement" */ "../components/BrandRequirementManagement/viewBrandRequirementPage.vue"
      ),
    meta: {
      requiredAuth: true,
      title: 'Brand Requirement Info'
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  //   meta: {
  //     requiredAuth: true
  //   }
  // },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfilePage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Profile'
    }
  },
  {
    path: '/login',
    name: 'login-page',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/sign-up',
    name: 'signup-page',
    component: () => import(/* webpackChunkName: "singup" */ '../views/SignUpPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Sign-up'
    }
  },
  {
    path: '/permissions',
    name: 'permission',
    component: () => import(/* webpackChunkName: "permission" */ '../views/PermissionPage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Permissions'
    }
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import(/* webpackChunkName: "Roles" */ '../views/RolePage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Roles'
    }
  },
  {
    path: '/offices',
    name: 'offices',
    component: () => import(/* webpackChunkName: "Offices" */ '../views/OfficePage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Offices'
    }
  },
  {
    path: '/landlords',
    name: 'landlords',
    component: () => import(/* webpackChunkName: "Landlord" */ '../views/LandlordPage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Landlords'
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "Company" */ '../views/CompanyPage.vue'),
    meta: {
      requiredAuth: true,
      title: 'Company Info'
    }
  },
  {
    path: '/landlords/:id',
    name: 'view-landlord',
    component: () => import(/* webpackChunkName: "View-landlord" */ '@/components/LandlordManagement/viewLandlord.vue'),
    meta: {
      requiredAuth: true,
      title: 'Landlord Info'
    }
  },
  {
    path: '/agprop-ai',
    name: 'agprop-ai',
    component: () => import(/* webpackChunkName: "agprop-ai" */ '../views/agpropAi.vue'),
    meta: {
      requiredAuth: true,
      title: 'agprop ai'
    }
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions-page',
    component: () => import(/* webpackChunkName: "login" */ '../views/TermsPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Terms and conditions'
    }
  },
  {
    path: '/policy',
    name: 'policy-page',
    component: () => import(/* webpackChunkName: "login" */ '../views/PolicyPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Policy'
    }
  },
  {
    path: '/payment-terms',
    name: 'payment-terms-page',
    component: () => import(/* webpackChunkName: "login" */ '../views/PaymentTermPage.vue'),
    meta: {
      requiredAuth: false,
      title: 'Payment Terms'
    }
  },
  {
    path: "/brand_requirements/public/:id",
    name: "external-brand-requirement",
    component: () =>
      import(
        /* webpackChunkName: "public-brand-requirement" */ "@/components/BrandRequirementManagement/brandRequirementExternal.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Add Public Property",
    },
  },
  {
    path: "/brand_requirements/private/:id",
    name: "external-brand-requirement",
    component: () =>
      import(
        /* webpackChunkName: "public-brand-requirement" */ "@/components/BrandRequirementManagement/brandRequirementExternal.vue"
      ),
    meta: {
      requiredAuth: true,
      title: "Add Public Property",
    },
  },

  {
    path: '/notification-redirect',
    name: 'notification-redirect',
    component: () => import('../views/NotificationRedirect.vue'),
    meta: {
      requiredAuth: false,
      title: 'notification redirect'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  localStorage.setItem("routeDetail", JSON.stringify({ to: to.path, from: from.path }));
  let isAuth = localStorage.getItem('accessToken')
  if (to.matched.some(record => record.meta.requiredAuth)) {
    if (!isAuth) {
      next('/login')
    } else {
      document.title = to.meta.title+ ' - Broker Network | agprop'
      next()
    }
  } else {
    document.title = to.meta.title+ ' - Broker Network | agprop'
    next()
  }
})


export default router
